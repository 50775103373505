import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid, Hidden, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from 'react-apollo';
import { ArrowForward } from '@material-ui/icons';
import { Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';

import Logo from '../../images/pourwall-logo.svg';
import BarScene from '../../images/illustration-signup.svg';
import Glassware from '../../images/glassware.svg';

import { SessionContext } from '../../contexts/session';
import { ADD_CONTACT, SIGN_UP } from './mutations';
import style from './styles';
import { FeedbackBar } from '../../components/FeedbackBar';
import { getMessageFromError } from '../../util/graphql';
import { VolumeUnitDropdown } from '../../components/VolumeUnitDropdown';
import { CurrencyDropdown } from '../../components/CurrencyDropdown';
import { HearAboutUsDropdown } from '../../components/HearAboutUsDropdown';
import { DEFAULT_CURRENCY } from '../../util/currencies';
import { DEFAULT_VOLUME_UNIT } from '../../util/volumeUnits';
import { DEFAULT_HEAR_ABOUT_US, HEAR_ABOUT_US_OPTIONS } from '../../util/hearAboutUsOptions';
import CustomLink from '../../components/CustomLink';

const useStyles = makeStyles(style);

const setCookie = (name, value) => {
  document.cookie = `${name}=${encodeURIComponent(value)}; expires=${new Date(
    Date.now() + 30 * 864e5
  ).toUTCString()}; path=/; domain=.${window.location.hostname.split('.').slice(-2).join('.')}`;
};

const getUtmParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const utmParams = {};
  for (const [k, v] of searchParams) {
    if (k.startsWith('utm')) {
      utmParams[k] = v;
    }
  }
  return Object.keys(utmParams).length ? JSON.stringify(utmParams) : null;
};

export const SignUp = ({ history, match }) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  let { user, token, login } = useContext(SessionContext);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [locationName, setLocationName] = useState('');
  const [currency, setCurrency] = useState(DEFAULT_CURRENCY);
  const [volumeUnit, setVolumeUnit] = useState(DEFAULT_VOLUME_UNIT);
  const [email, setEmail] = useState(match?.params?.email || '');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState();
  const [hearAboutUs, setHearAboutUs] = useState(DEFAULT_HEAR_ABOUT_US);
  const [freeTextHearAboutUs, setFreeTextHearAboutUs] = useState('');
  const [isUTM, setIsUTM] = useState(false);

  const [validating, setValidating] = useState(false);

  const [signUp, { loading, data }] = useMutation(SIGN_UP);
  const [addContact] = useMutation(ADD_CONTACT);

  const go = async () => {
    setErrorMessage(null);
    setValidating(true);
    try {
      if (firstName && lastName && locationName && email && password && hearAboutUs !== DEFAULT_HEAR_ABOUT_US) {
        if (hearAboutUs === HEAR_ABOUT_US_OPTIONS.OTHER && !freeTextHearAboutUs) {
          return;
        }
        const aboutUs = hearAboutUs === HEAR_ABOUT_US_OPTIONS.OTHER ? freeTextHearAboutUs : hearAboutUs;
        const appLanguage = i18n.language ?? i18n.languages[0];

        await signUp({
          variables: {
            firstName,
            lastName,
            locationName,
            currency,
            volumeUnit,
            email,
            password,
            appLanguage,
            hearAboutUs: aboutUs
          }
        });

        ReactGA.event({
          category: 'Registration',
          action: 'Sign up',
          label: 'Signup user',
          dimension1: aboutUs,
          dimension2: locationName
        });
        if (typeof window.gtag === 'function') {
          window.gtag('event', 'Sign up', {
            event_category: 'Registration',
            event_label: 'Signup user',
            dimension1: aboutUs,
            dimension2: locationName
          });
        }
      }
    } catch (e) {
      setErrorMessage(getMessageFromError(e));
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const emailFromQuery = queryParams.get('email');
    const utmFromQuery = getUtmParams();
    const utmFromCookie = document.cookie
      .split('; ')
      .find((row) => row.startsWith('utm='))
      ?.split('=')?.[1];
    const utmValue = utmFromQuery || utmFromCookie;

    if (utmFromQuery) {
      setCookie('utm', utmFromQuery);
    }

    if (utmValue) {
      setFreeTextHearAboutUs(`utm-${utmValue}`);
      setHearAboutUs(HEAR_ABOUT_US_OPTIONS.OTHER);

      setIsUTM(true);
    }
    if (emailFromQuery) {
      setEmail(emailFromQuery);
    }
  }, []);

  useEffect(() => {
    if (match?.params?.email) {
      history.push({ pathname: '/signup' });
      try {
        addContact({ variables: { email: match.params.email } });
      } catch (e) {
        console.log(e);
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data?.signup?.token && data?.signup?.user) {
      login(data.signup.user, data.signup.token);
      history.push('/taps');
    }
  }, [data, history, login]);

  if (user && token) return <Redirect to="/taps" />;

  return (
    <Grid container direction="row" className={classes.root}>
      <Grid item className={classes.header} xs={12} md={8}>
        <img src={Logo} alt="POURWALL" className={classes.logo} />
        <Hidden mdUp>
          <Typography variant="h1">{t('Signup.LetsGetStarted')}</Typography>
          <p>{t('Signup.UpAndRunning')}</p>
        </Hidden>
        <Hidden smDown>
          <div className={classes.product}>
            <div className={classes.productText}>
              <img alt="" src={Glassware} className={classes.glassware} />
              <h1 className={classes.productHeading}>{t('Signup.SimpleDigitalBeverageMenus')}</h1>
              <p className={classes.productDescription}>{t('Signup.CreatedForBars')}</p>
            </div>
            <img alt="" src={BarScene} className={classes.barScene} />
          </div>
        </Hidden>
      </Grid>
      <Grid item className={classes.content} xs={12} md={4}>
        <Hidden smDown>
          <Typography variant="h1">{t('Signup.LetsGetStarted')}</Typography>
          <p>{t('Signup.UpAndRunning')}</p>
        </Hidden>
        <form className={classes.form}>
          <TextField
            variant="outlined"
            fullWidth
            label={t('Signup.FirstName')}
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
            error={validating && !firstName}
          />
          <TextField
            variant="outlined"
            fullWidth
            label={t('Signup.LastName')}
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
            error={validating && !lastName}
          />
          <TextField
            variant="outlined"
            fullWidth
            label={t('Signup.LocationName')}
            onChange={(e) => setLocationName(e.target.value)}
            value={locationName}
            error={validating && !locationName}
          />
          <VolumeUnitDropdown volumeUnit={volumeUnit} setVolumeUnit={setVolumeUnit} label={t('Signup.VolumeUnit')} />
          <CurrencyDropdown currency={currency} setCurrency={setCurrency} label={t('Signup.Currency')} />
          <p />
          <TextField
            variant="outlined"
            fullWidth
            label={t('Signup.Email')}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            error={validating && !email}
          />
          <TextField
            variant="outlined"
            fullWidth
            label={t('Signup.CreatePassword')}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            error={validating && !password}
          />
          {!isUTM && (
            <>
              <HearAboutUsDropdown
                aboutUs={hearAboutUs}
                setHearAboutUs={setHearAboutUs}
                error={validating && hearAboutUs === DEFAULT_HEAR_ABOUT_US}
                label={t('Signup.HowDidYouHearAboutUs')}
              />
              {hearAboutUs === HEAR_ABOUT_US_OPTIONS.OTHER && (
                <TextField
                  variant="outlined"
                  fullWidth
                  label={t('Signup.HowDidYouHearAboutUs')}
                  onChange={(e) => setFreeTextHearAboutUs(e.target.value)}
                  value={freeTextHearAboutUs}
                  error={validating && !freeTextHearAboutUs}
                />
              )}
            </>
          )}
          <Button className={classes.button} variant="contained" color="primary" onClick={go} disabled={loading}>
            {t('Signup.Next')} <ArrowForward />
          </Button>
        </form>
        <div>
          <p>
            {t('Signup.AlreadyHaveAnAccount')} <CustomLink href="/login">{t('Signup.Login')}</CustomLink>.
          </p>
        </div>
      </Grid>
      <FeedbackBar message={errorMessage} />
    </Grid>
  );
};
